@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  background-image: url('https://sqrx.com/static/img/photos/blurry.png');
  background-repeat: no-repeat;
  background-position: 0% 0%;
  background-color: #f4f4fc;
}

body {
  margin: 0;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slider-input {
  position: relative;
  margin-top: 20px;
}

.slider-input label {
  position: absolute;
  left: 10px;
  border-radius: 6px;
  transition: transform 0.3s ease-out, font-size 0.3s ease-out;
  transform: translateY(3px);
}

.slider-input input {
  position: absolute;
  left: 10px;
  top: 0;
  height: 100%;
  width: 95%;
  border-radius: 6px;
}

.slider-input input::placeholder {
  color: transparent;
}

.slider-input input:focus + label,
.slider-input input:valid + label {
  transform: translateY(calc(-98%));
  font-size: 12px;
  background: #fff;
  padding: 0 10px;
  width: auto;
  height: auto;
}

.slider-input input:focus::placeholder {
  color: #60697b;
}

details summary svg {
  transform: rotate(90deg);
}
details[open] summary svg {
  transform: rotate(-90deg);
}

.faq-target {
  scroll-margin-top: 80px;
}

.gradient-landing {
  background-image: linear-gradient(
    43deg,
    #4158d0 0,
    #c850c0 46%,
    #ffcc70 100%
  );
}

@media screen and (min-width: 1442px) {
  .vp\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.vimeo-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}
